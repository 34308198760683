import * as React from "react"

import {
    Box,
    Stack,
    useDisclosure,
    Flex,
    Button,

    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,

    Heading,
    Text,
    TextProps,
    Avatar,

    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,

    Card,
    CardBody,
    Image,

    CardFooter,

    VStack,
    Center,

    AspectRatio,

    Link as ChakraLink,
} from "@chakra-ui/react"

import { PhoneIcon, EmailIcon } from '@chakra-ui/icons'

import {
    COLOR_Yellow,
    COLOR_Pink,
    COLOR_Buttons,
    COLOR_Titles
} from './colors'

import {
    max_width_page,
    margin
} from '../utils/constants'

export function Contacts() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Button
                bgColor={COLOR_Yellow}
                onClick={onOpen}>
                Contacts
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontFamily={'Arial, sans-serif'} color={COLOR_Titles}>Nous contacter</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexDir={'column'}>
                            <Flex padding={'5'} alignItems='center' flexWrap='wrap'>
                                <Avatar size={'xl'} name='Pauline' src='img/pauline.jpg' />

                                <Box padding={'5'}>
                                    <Flex direction={'column'}>
                                        <Heading fontFamily={'Arial, sans-serif'} color={COLOR_Titles} size='sm'>Pauline</Heading>

                                        <Box padding={1}>
                                            <Popover>
                                                <PopoverTrigger>
                                                    <Button leftIcon={<PhoneIcon />} bgColor={COLOR_Buttons} variant='solid'>
                                                        Téléphone
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent>
                                                    <PopoverHeader>079 / 338 99 02</PopoverHeader>
                                                </PopoverContent>
                                            </Popover>
                                        </Box>

                                        <Box padding={1}>
                                            <ChakraLink href={`mailto:schaller.pauline@gmail.com`}>
                                                <Button leftIcon={<EmailIcon />} bgColor={COLOR_Buttons} variant='solid'>
                                                    Email
                                                </Button>
                                            </ChakraLink>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Flex>

                            <Flex padding={'5'} alignItems='center' flexWrap='wrap'>
                                <Avatar size={'xl'} name='Sofiane' src='img/sofiane.jpg' />

                                <Box padding={'5'}>
                                    <Flex direction={'column'}>
                                        <Heading fontFamily={'Arial, sans-serif'} color={COLOR_Titles} size='sm'>Sofiane</Heading>

                                        <Box padding={1}>
                                            <Popover>
                                                <PopoverTrigger>
                                                    <Button leftIcon={<PhoneIcon />} bgColor={COLOR_Buttons} variant='solid'>
                                                        Téléphone
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent>
                                                    <PopoverHeader>079 / 629 75 16</PopoverHeader>
                                                </PopoverContent>
                                            </Popover>
                                        </Box>

                                        <Box padding={1}>
                                            <ChakraLink href={`mailto:sofiane.gadi@gmail.com`}>
                                                <Button leftIcon={<EmailIcon />} bgColor={COLOR_Buttons} variant='solid'>
                                                    Email
                                                </Button>
                                            </ChakraLink>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button bgColor={COLOR_Pink} mr={3} onClick={onClose}>
                            Fermer
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export interface MapInfo {
    name: string
    image: string
    map: string
    street: string
    city: string
};

export function MapCard(info: MapInfo) {
    return (
        <>
            <Card
                mt={4}
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                maxW={'550px'}
            >

                <ChakraLink
                    maxW={{ base: '100%', sm: '200px' }}
                    objectFit='cover'
                    href={info.image} isExternal>
                    <Image
                        src={info.image}
                        alt={info.name}
                    />
                </ChakraLink>

                <Stack>
                    <CardBody>
                        <Heading fontFamily={'Arial, sans-serif'} color={COLOR_Titles} size='sm'>{info.name}</Heading>

                        <Text>{info.street}</Text>
                        <Text>{info.city}</Text>

                        <ChakraLink href={info.map} isExternal>
                            <Button variant='solid' bgColor={COLOR_Pink}>
                                Carte
                            </Button>
                        </ChakraLink>
                    </CardBody>
                </Stack>
            </Card>
        </>
    );
}

interface ActivitesParam {
    name: string,
    description: string,
    link: string,
    picture: string
};

export function ActivitesCard({ name, description, link, picture }: ActivitesParam) {
    return (
        <>
            <Card maxW='sm'>
                <CardBody>
                    <Image
                        src={picture}
                        alt='Green double couch with wooden legs'
                        borderRadius='lg'
                    />
                    <Stack mt='6' spacing='3'>
                        <Heading fontFamily={'Arial, sans-serif'} color={COLOR_Titles} size='md'>{name}</Heading>
                        <Text>
                            {description}
                        </Text>
                    </Stack>
                </CardBody>
                <CardFooter>
                    <ChakraLink href={link} isExternal>
                        <Button variant='solid' bgColor={COLOR_Buttons}>
                            Plus d'informations
                        </Button>

                    </ChakraLink>
                </CardFooter>
            </Card>
        </>
    );
}

interface SongParam {
    name: string,
    link: string,
};

export function SongCard({name, link}: SongParam) {
    return (
        <>
            <Card w='600px'>
                <CardBody >
                    <AspectRatio ratio={1}>
                        <iframe
                            title={name}
                            src={link}
                            allowFullScreen
                        />
                    </AspectRatio>
                </CardBody>
            </Card>
        </>
    );
}

export type PageContent = {
    title: String;
    body: React.ReactNode;

};

export function Page(pageContentArr: PageContent[]) {
    React.useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <Center>
            <VStack
                spacing={5}
                align='stretch'
                margin={margin}
                maxW={max_width_page}
                alignContent={"center"}>
                <>
                    {pageContentArr.map(({ title, body }, index) => (
                        <div key={index}>
                            <Heading fontFamily="Arial, sans-serif" color={COLOR_Titles} size="lg">
                                {title}
                            </Heading>
                            <div>
                                {body}
                            </div>
                        </div>
                    ))}
                </>
            </VStack>
        </Center>
    );
}

export const AlignedText: React.FC<TextProps> = (props) => {
    return <Text align="justify" text-align="left" {...props} />;
};
