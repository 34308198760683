import {
    Page,
    PageContent,
    AlignedText
} from '../utils/utils'

import {
    Link as ChakraLink,
    Image,
    List,
    ListItem,
    Spacer,
    Flex
} from "@chakra-ui/react"

import {
    COLOR_Link,
} from '../utils/colors'


export const Photos = () => {
    const pageContentArr: PageContent[] = [
        {
            title: "Photos",
            body:
                <List>
                    <AlignedText>
                        Les photos de notre mariage ont été prises par <ChakraLink isExternal={true} color={COLOR_Link} textDecoration="underline" href={'https://imagipeg.com/'}>Pierre-Emmanuel Grandjean</ChakraLink>. Un immense merci à lui!
                    </AlignedText>
                    <AlignedText>
                        Pour les télécharger, clique <ChakraLink isExternal={true} color={COLOR_Link} textDecoration="underline" href={'https://drive.proton.me/urls/K49SWNN0JG#YNwc91Q4bvuO'}>sur ce lien</ChakraLink>.
                    </AlignedText>
                    <AlignedText>
                        Le mot de passe pour accéder au dossier sera distribué avec le message de remerciements. 
                        Si tu es impatient de voir les photos (ou que tu n'as pas reçu de remerciements &#x1F633;), écris-nous! Nos contacts sont accessbile au bas de cette page.
                    </AlignedText>
                    <AlignedText>
                        Bon visionnage!
                    </AlignedText>
                    

                    <ListItem>
                        <Flex >
                            <Spacer />
                            <Image
                            rounded={'md'}
                            alt={'foret'}
                            src={
                                'img/DSC_1454.jpg'
                            }
                            objectFit={'cover'}
                        /><Spacer />
                        </Flex>
                    </ListItem>

                </List>
        }
    ];
    return Page(pageContentArr);
}
