
export const COLOR_Yellow : string = '#EDAE49'
export const COLOR_Pink : string = '#D1495B'
export const COLOR_Turquoise : string = '#00798C'
export const COLOR_LBlue : string = '#30638E'
export const COLOR_DBlue : string = '#003D5B'
export const BLACK : string = '#000000'
export const COLOR_Buttons : string = '#5f9dba'
export const COLOR_Link : string = '#30638E'
export const COLOR_Titles : string = '#808080'
