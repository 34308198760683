import './App.css'

import * as React from "react"

import {
  ChakraProvider,
  Grid,
  GridItem,
  theme,
  Flex,
  Spacer,
  Box,
  Heading,
  Link as ChakraLink,
  Image,
  useMediaQuery
} from "@chakra-ui/react"

import { DrawerMenu } from './utils/NavigationBar'
import {
  Contacts,
  AlignedText
} from './utils/utils'

import { Routes, Route, Outlet } from "react-router-dom";
import { InfosCeremonie, InfosSouper, InfosActivites } from './pages/info'
import Home from './pages/home'
import { Photos } from './pages/photos'
// import { Chants } from './pages/chants'
import { Cadeaux } from './pages/cadeaux'

// import Registration from "./pages/registration";

import { Link as ReactRouterLink } from 'react-router-dom'

import {
  COLOR_Link
} from './utils/colors'


export const App = () => (

  <ChakraProvider theme={theme}>

    <ClearData />

    <Grid
      mt='0'
      mb='0'
      ml='0'
      mr='0'

      templateAreas={`"header"
                  "main"
                  "footer"`}
      gridTemplateRows={'90px 1fr 90px'}
      h='calc(100vh)'
      gap='4'
      color='blackAlpha.700'
      fontWeight='bold'
    >
      <GridItem pl='2' area={'header'} alignContent={'center'} bgColor={'#787777'}
      /*bgGradient={`linear(to-b, ${COLOR_LBlue}, #FFFFFF)`}*/
      >

        <Flex>
          <Box padding={3}>
            {DrawerMenu()}
          </Box>
          <Spacer />
          <Heading as='h1'>
            <ChakraLink to={"/"} as={ReactRouterLink} _hover={{ textDecoration: 'none' }} _focus={{ textDecoration: 'none' }}>
              <DisplayBanner />
            </ChakraLink>
          </Heading>
          <Spacer />
        </Flex>
      </GridItem>

      <GridItem pl='2' area={'main'}>
        {SetRoute()}
      </GridItem>
      <GridItem pl='2' area={'footer'} bgColor={'#787777'}
      /*bgGradient={`linear(to-b, #FFFFFF, ${COLOR_LBlue})`}*/>
        {/*<Divider size={'4px'} borderWidth={'2px'} />*/}

        <Flex direction={'row'} mb='0'>

          <Box padding={5}>
            {Contacts()}
          </Box>
          <Spacer />
          <Image
            padding={0}
            align="flex-end"
            mb='0'
            mr='5'
            width='auto'
            height='80px'
            src='img/bas.png' alt='bas' />
        </Flex>
      </GridItem>
    </Grid>
  </ChakraProvider >
)

function DisplayBanner() {
  const [isWideScreen] = useMediaQuery('(min-width: 700px)')

  return (
    <>
      <Flex >
        <Spacer />
        {isWideScreen ?
          <Image src='img/PaulineSofiane_Computer.png' alt='situation' width='auto' height='80px' /> :
          <Image src='img/PaulineSofiane_Phone.png' alt='situation' width='auto' height='70px' />}
        <Spacer />
      </Flex></>
  )
}


function SetRoute() {
  return (<Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      {/*<Route path="registration" element={<Registration />} />*/}
      <Route path="ceremonie" element={<InfosCeremonie />} />
      <Route path="souper" element={<InfosSouper />} />
      <Route path="activites" element={<InfosActivites />} />
      <Route path="photos" element={<Photos />} />
      {/*<Route path="chants" element={<Chants />} />*/}
      <Route path="cadeaux" element={<Cadeaux />} />
      <Route path="de" element={<Deutsch />} />

      <Route path="*" element={<NoMatch />} />
    </Route>
  </Routes>);
}

function Layout() {
  return (
    <div>
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}


function NoMatch() {
  return (
    <>
      <AlignedText>
        Alors là, aucune idée où tu voulais aller! Retour à la <ChakraLink to={"/"} color={COLOR_Link} as={ReactRouterLink} textDecoration="underline">page d'accueil</ChakraLink>
      </AlignedText>
    </>
  );
}

function Deutsch() {
  return (<>
    <AlignedText>
      Et pour les suisse-allemands, c'est la même chose. Zurück zur <ChakraLink to={"/"} color={COLOR_Link} as={ReactRouterLink} textDecoration="underline">Startseite</ChakraLink>
    </AlignedText>
  </>
  );
}

const ClearData = () => {
  React.useEffect(() => {
    // Function to execute when the component is unmounted or page is closed
    return () => {
      console.log('Component is unmounted or page is closed');
      localStorage.clear();
      // Execute cleanup tasks here, such as clearing intervals or removing event listeners
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  return (
    <div>
      {/* Your component's JSX */}
    </div>
  );
};