'use client'

import * as React from "react"

import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Stack,
    StackDivider,
    Icon,
    Link as ChakraLink,
    Button,
    /*useDisclosure,

    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter*/
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import {
    PiChurchDuotone
} from 'react-icons/pi'

import {
    LuPartyPopper
} from 'react-icons/lu'


import {
    COLOR_Yellow,
    BLACK,
    COLOR_Buttons,
    COLOR_Titles,
    // COLOR_Link,
    COLOR_Pink
} from '../utils/colors'

import {
    max_width_page
} from '../utils/constants'

import {
    AlignedText
} from '../utils/utils'

interface FeatureProps {
    text: string
    link: string
    iconBg: string
    icon?: ReactElement
}

const Feature = ({ text, link, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={iconBg}>
                {icon}
            </Flex>
            <ChakraLink fontFamily={'Arial, sans-serif'} color={COLOR_Titles} to={link} as={ReactRouterLink}>{text}</ChakraLink>
        </Stack>
    )
}

export default function Home() {
    return (
        <>
            {/*<AlertDialogNewPlace />*/}
            <Container maxW={max_width_page} py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4}>
                        <Heading color={COLOR_Titles} fontFamily={'Arial, sans-serif'}>Voici le site de notre mariage!</Heading>
                        <AlignedText fontSize={'lg'}>
                            {/*Il aura lieu le 17 août 2024 en l'Eglise des Pommerats (et non à Saint-Ursanne!). Nous nous réjouissons de vivre cet événement unique avec vous.*/}
                            Il a eu lieu le 17 août 2024 en l'Eglise des Pommerats (et non à Saint-Ursanne!). Nous avons eu énormément de plaisir à vivre cet événement unique avec vous. Un tout grand merci!
                            </AlignedText>

                        <AlignedText fontSize={'lg'}>
                            Pour regarder les photos du mariage, cliquez sur le button ci-dessous.
                        </AlignedText>
                        <ReactRouterLink to="/photos">
                                <Button bgColor={COLOR_Pink} >
                                    Les photos!
                                </Button>
                        </ReactRouterLink>
                        <AlignedText fontSize={'lg'}>
                            Vous trouverez dans les rubriques ci-dessous toutes les informations utiles.
                        </AlignedText>
                        <Stack
                            spacing={4}
                            divider={
                                <StackDivider />
                            }>
                            <Feature
                                icon={<Icon as={PiChurchDuotone} color={BLACK} w={5} h={5} />}
                                iconBg={COLOR_Buttons}
                                text={'La Cérémonie'}
                                link={"/ceremonie"}
                            />
                            <Feature
                                icon={<Icon as={LuPartyPopper} color={BLACK} w={5} h={5} />}
                                iconBg={COLOR_Yellow}
                                text={'Le Souper'}
                                link={"/souper"}
                            />
                            {/*<AlignedText fontSize={'lg'}>
                                N'oubliez pas de vous inscrire, en cliquant sur le bouton ci-dessous (jusqu'au 1<sup>er</sup> mai).
                                Nous espérons vous voir nombreuses et nombreux!
                            </AlignedText>
                            <ReactRouterLink to="/registration">
                                <Button bgColor={COLOR_Buttons} >
                                    S'inscrire!
                                </Button>
                            </ReactRouterLink>*/}
                        </Stack>
                    </Stack>
                    <Flex>
                        <Image
                            rounded={'md'}
                            alt={'foret'}
                            src={
                                'img/mosaic_35.png'
                            }
                            objectFit={'cover'}
                        />
                    </Flex>
                </SimpleGrid>
            </Container>
        </>
    )
}

/*function AlertDialogNewPlace() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<HTMLButtonElement | null>(null);

    React.useEffect(() => {
        const alreadyOpened = localStorage.getItem('alertWindowOpened');
        if (!alreadyOpened) {
            localStorage.setItem('alertWindowOpened', 'true');
            onOpen();
        }
    }, [onOpen]);

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Changement de lieux
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Pour cause de <ChakraLink isExternal={true} color={COLOR_Link} textDecoration="underline" href={'https://www.j3l.ch/fr/P43659/a-faire/manifestations/sport/course-de-cote-st-ursanne-les-rangiers'}>course des Rangiers</ChakraLink>
                            , la célébration aura toujours lieu à la même heure, mais en l'Eglise des Pommerats.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <ChakraLink onClick={onClose} to={"/ceremonie"} as={ReactRouterLink}>
                            <Button ref={cancelRef} bgColor={COLOR_Buttons} variant='solid'>
                                Où ça?
                            </Button>
                            </ChakraLink>
                            <Button ref={cancelRef} onClick={onClose} bgColor={COLOR_Yellow} variant='solid' ml={3}>
                                Ca marche!
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}*/