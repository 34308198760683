import {
    VStack,
    Image,
    Box,
    Stack,
    Heading,
    Modal,
    ModalContent,
    ModalFooter,
    Button,
    ModalBody,
    Link as ChakraLink,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    useDisclosure,
    Text
} from "@chakra-ui/react"

import {
    Page,
    PageContent,
    AlignedText,
} from '../utils/utils'

import {
  COLOR_Pink,
  COLOR_Titles,
  COLOR_Link,
} from '../utils/colors'


import { Flex, Grid } from "@chakra-ui/react";

interface GiftProps {
    imageSrc: string;
    title: string;
}


const gifts = [
    {
      id: "1",
      title: "Des couverts",
      imageSrc: "https://media.istockphoto.com/id/531752971/de/foto/löffel-und-gabel-mit-reflexion.jpg?s=1024x1024&w=is&k=20&c=HX7JbAVxfWa1_hhyR8krj5ReedUun3YS6UUYIzhF9Xk=",
    },
    {
      id: "2",
      title: "Des assiettes",
      imageSrc: "https://media.istockphoto.com/id/1223501360/de/foto/reis-mit-meeresfrüchten-muscheln-garnelen-und-oktopus-auf-einem-isolierten-weißen-hintergrund.jpg?s=1024x1024&w=is&k=20&c=21_uQcg_z3ekxa-4hihGasky3Ync2zRtxKXHySgXWkY=",
    },
    {
      id: "3",
      title: "Des verres",
      imageSrc: "https://media.istockphoto.com/id/1084996534/photo/wine-glasses-in-a-row-buffet-table-celebration-of-wine-tasting-nightlife-celebration-and.jpg?s=1024x1024&w=is&k=20&c=31_8_ePS2_gLvgqAIX-ZUDtIkkooaRTY9T-sShuTgjI=",
    },
    {
      id: "4",
      title: "Des tupperwares",
      imageSrc: "https://media.istockphoto.com/id/1175309233/photo/remodeled-contemporary-classic-kitchen-design-with-pantry-storage.jpg?s=1024x1024&w=is&k=20&c=qa-HUdy_YQeY385eGKpsUeILMtfJfMLyVq7RV-DUx_A=",
    },
    {
      id: "5",
      title: "Un ensemble de couteaux de cuisine",
      imageSrc: "https://media.istockphoto.com/id/1127558653/photo/set-of-knives-for-kitchen.jpg?s=1024x1024&w=is&k=20&c=xAm-HR-Y-2uyZ2DM-dQ30QTHj5_Dg0QqL5aPC0BOqac=",
    },
    {
        id: "6",
        title: "Un robot ménager",
        imageSrc: "https://media.istockphoto.com/id/1175759546/photo/robotic-chef-cooking.jpg?s=1024x1024&w=is&k=20&c=MH8eutJcjVrZLpwKj8ptH5kpie8M9XLce2PdmZ_7GAg=",
      },
      {
        id: "7",
        title: "Des rideaux",
        imageSrc: "https://media.istockphoto.com/id/1494524186/de/foto/junge-frau-die-durch-vorhänge-schaut-ihr-gesicht-ein-bild-der-überraschung.jpg?s=1024x1024&w=is&k=20&c=kq_pzxhPTfLgSqugwFz-ZCWm_QErdwZA_NmWCSfB9l0=",
      },
      {
        id: "8",
        title: "Des housses et draps de lit",
        imageSrc: "https://media.istockphoto.com/id/1528100429/de/foto/bettwäsche-reinigung-und-frisch-mit-einer-schwarzen-frau-die-stoff-oder-textil-riecht-während.jpg?s=1024x1024&w=is&k=20&c=LjW_ESUC4CrOli_jmqN2p7DThkQ4YJfRuGTCfTlBMZM=",
      },
      {
        id: "9",
        title: "Deux tables de nuit",
        imageSrc: "https://media.istockphoto.com/id/1441419727/photo/vase-with-beautiful-eucalyptus-branches-book-and-candles-on-nightstand-in-bedroom.jpg?s=1024x1024&w=is&k=20&c=xvhgnLB8CONQe7TerHf0Na9MzkeZUBcspoGHUkr9ZdA=",
      },
      {
        id: "10",
        title: "Des plantes d'intérieur",
        imageSrc: "https://media.istockphoto.com/id/1280154279/de/foto/geben-sie-ihrem-haus-eine-gute-dosis-grün.jpg?s=1024x1024&w=is&k=20&c=VfQY6DUIVgH-rrW5k9YW9gEJMEhr1w1RCf05RwTf0kI=",
      },
      {
        id: "14",
        title: "Des objets d'art",
        imageSrc: "https://media.istockphoto.com/id/1306691787/de/foto/büste-skulptur-mit-sonnenbrille.jpg?s=1024x1024&w=is&k=20&c=ID0ALPoEGvV64RgjXdpIK8vUKBVIzKhyKpEiyTWrL1g=",
      },
      {
        id: "11",
        title: "Un arbre fruitier à planter",
        imageSrc: "https://media.istockphoto.com/id/1338685542/de/vektor/newton-bekommt-einen-apfel-auf-den-kopf.jpg?s=1024x1024&w=is&k=20&c=vEriF_HVYaoKN3BDbE8tP_YphjrltUE6a-tpqlJ_68I=",
      },
      {
        id: "12",
        title: "Notre voyage de noce",
        imageSrc: "https://media.istockphoto.com/id/154930728/de/foto/skifahren-braut.jpg?s=612x612&w=0&k=20&c=n7eSVhagOoUjYrs70ReVbt4trTnENVpheL_da0dBdxE=",
      },
      {
        id: "13",
        title: "Une maison! 😉",
        imageSrc: "https://media.istockphoto.com/id/1344597775/de/foto/balmoral-castle-schottische-residenz-der-königlichen-familie.jpg?s=1024x1024&w=is&k=20&c=Y6N1yrEpIg9_Kso7rZNoo8BpDtYcXu2rRxZDi1MAH5Y=",
      },
  ];


export const Gift: React.FC<GiftProps> = ({ imageSrc, title }) => (
    <Stack p={{ base: "0 2rem" }}>
      <Image objectFit="cover" src={imageSrc} alt={title} />
      <Box>
        {title}
      </Box>
    </Stack>
  );

function GiftGrid() {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        maxW={{ xl: "1200px" }}
        m="0 auto"
        minH="100vh"
      >
        <Grid
          w="full"
          gridGap="5"
          gridTemplateColumns="repeat( auto-fit, minmax(300px, 1fr) )"
        >
          {gifts.map((p) => (
            <Gift key={p.id} {...p} />
          ))}
        </Grid>
      </Flex>
    );
  }

export const Cadeaux = () => {
    const pageContentArr: PageContent[] = [
        {
            title: "Cadeaux",
            body:
                <>
                    <VStack spacing={4} align="left">
                        <AlignedText>
                            Votre présence constitue le plus beau des cadeaux! Partager cette journée avec vous constituera un souvenir inoubliable qui comblera entièrement nos coeurs
                            et ne demande aucun complément.
                        </AlignedText>          
                        {/* , et parce que l'avancement technologique ne connaît pas de limites
                            comme notre futur matériel s'annonce incertain et pour éviter

                            afin d'éviter de remplir la cave de nos parents
                        */}
                        {/* Bitcoin, M-PESA */}              
                        <AlignedText>                          
                            Cependant, à la demande de certain-e-s d'entre vous et pour vous éviter des tracasseries inutiles, nous avons tout de même constitué une liste d'objets que nous désirons acquérir prochainement (ou non) ci-dessous (photos non contractuelles!).
                        </AlignedText>
                        <AlignedText>
                            Afin d'éviter les doublons et parce que notre cocon où jouir des biens matériaux est particulièrement incertain en cette période (et probablement limités à 46kg),
                            une boîte à lettres sera disposée dans la Halle. Pour les plus technologiques d'entre vous, nous possédons Twint (nos numéros de téléphone sont dans les contacts au bas de cette page) ainsi qu'un compte bancaire pour le mariage ({BankAccount()}).
                        </AlignedText>
                        <AlignedText>
                            Dans chacun de ces cas, il est possible par quelques mots d'indiquer un des éléments de la liste (ou toute autre chose!) qui vous tiendrait à coeur de nous offrir.
                            Le moment venu, nous serons heureux de penser à vous en l'utilisant!
                        </AlignedText>

                        {/*
                          BankAccount()
                        */}

                        <Heading as="b" fontFamily="Arial, sans-serif" color={COLOR_Titles} size="md">
                            Propositions 
                        </Heading>

                        <GiftGrid/>

                        {/*<AlignedText>
                            <List spacing={3}>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Couverts
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Assiettes
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Verres
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Tupperware
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Ensemble de couteaux de cuisine
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Robot ménager
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Rideaux
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Housses et draps de lit
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Table de nuit
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Plantes d'intérieur
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Arbre fruitier à planter
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Voyage de noce
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Une maison! &#x1F609;
                                </ListItem>
                            </List>
                        </AlignedText>

                        {/ *
                        - couverts
                        - assiettes
                        - verres
                        - tupperware
                        - un ensemble de couteaux de cuisine
                        - robot ménager

                        - des rideaux
                        - housses et draps de lit housses et draps housses (enfourage de lits?)

                        - plantes d'intérieurs
                        - objets d'art
                        - un arbre fruitier à planter

                        - voyage de noce

                        - maison
                        * /}

                        {/ *                        
                        - beamer
                        * /}
                        */}
                    </VStack>
                </>
        }
    ];
    return Page(pageContentArr);
}




function BankAccount() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
      <>
          <ChakraLink
              onClick={onOpen}
              color={COLOR_Link} textDecoration="underline">
              ici, les détails du compte
          </ChakraLink>

          <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                  <ModalHeader fontFamily={'Arial, sans-serif'} color={COLOR_Titles}>Nos coordonnées bancaires</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                      <Flex flexDir={'column'}>
                          <Flex padding={'5'} alignItems='center' flexWrap='wrap' justifyContent="center">

                          <Box display="flex" justifyContent="center" alignItems="center">
                            <Image src='img/qr_compte.png' />
                          </Box>

                              <Box padding={'5'}>
                                  <Flex direction={'column'}>
                                      <Box padding={1}>
                                      <Text as='b'>Nom:</Text> Sofiane Gadi
                                      </Box>

                                      <Box padding={1}>
                                      <Text as='b'>Adresse:</Text> Hintere Bahnhofstrasse 79, 5000 Aarau
                                      </Box>

                                      <Box padding={1}>
                                      <Text as='b'>IBAN:</Text> CH23 0844 0258 4928 52001
                                      </Box>

                                      <Box padding={1}>
                                      <Text as='b'>Adresse de la banque:</Text> Bank Cler AG, Aeschplatz 3, 4052 Basel
                                      </Box>


                                  </Flex>
                              </Box>
                          </Flex>
                      </Flex>
                  </ModalBody>

                  <ModalFooter>
                      <Button bgColor={COLOR_Pink} mr={3} onClick={onClose}>
                          Fermer
                      </Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
      </>
  )
}




