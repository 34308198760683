import * as React from "react"

import {
    IconButton,
    Box,
    useDisclosure,
    Flex,
    Center,
    Icon,
    Spacer,
} from "@chakra-ui/react"

import {
    AiOutlineHome
} from 'react-icons/ai'

import {
    PiChurchDuotone
} from 'react-icons/pi'

import {
    LuPartyPopper
} from 'react-icons/lu'

import {
    MdOutlineSportsTennis
} from 'react-icons/md'

/*import {
    BsPen
} from 'react-icons/bs'*/

// import { IoMusicalNotesOutline } from "react-icons/io5";

import { IoCameraOutline } from "react-icons/io5";

import { AiOutlineGift } from "react-icons/ai";

import { Link as ReactRouterLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import { Link as ChakraLink } from '@chakra-ui/react'

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    FlexProps
} from '@chakra-ui/react'

import { IconType } from 'react-icons'

import { GiHamburgerMenu } from "react-icons/gi"; // https://react-icons.github.io/react-icons/

import { Contacts } from '../utils/utils'

import {
    COLOR_Yellow,
    COLOR_Buttons
} from '../utils/colors'

type voidFunc = () => void;

interface NavItemProps extends FlexProps {
    icon: IconType,
    name: string,
    link: string,
    onClose?: voidFunc,
}
const NavItem = ({ icon, name, link, onClose, ...rest }: NavItemProps) => {
    const navigate = useNavigate();
    return (
        <Box
            as="a"
            href="#"
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            onClick={() => {
                navigate(link);
                if (onClose != null) {
                    onClose();
                }
            }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: COLOR_Buttons,
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {name}
            </Flex>
        </Box>
    )
}




export function DrawerMenu() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef(null)

    const menuItems: Array<NavItemProps> = [
        { name: 'Accueil', icon: AiOutlineHome, link: "/" },
        { name: 'La Cérémonie', icon: PiChurchDuotone, link: "/ceremonie" },
        { name: 'Le Souper', icon: LuPartyPopper, link: "/souper" },
        { name: 'Le Dimanche', icon: MdOutlineSportsTennis, link: "/activites" },
        // { name: 'Inscription', icon: BsPen, link: "/registration" },
        // {name: 'Chants', icon: IoMusicalNotesOutline, link: "/chants"},
        { name: 'Photos', icon: IoCameraOutline, link: "/photos" },
        { name: 'Cadeaux', icon: AiOutlineGift, link: "/cadeaux" },
    ]

    return (
        <Flex>
            <Center>
                <IconButton aria-label="menu" ref={btnRef} bgColor={COLOR_Yellow} onClick={onOpen} icon={<GiHamburgerMenu />}>
                </IconButton>

                <Drawer
                    isOpen={isOpen}
                    placement='left'
                    size='xs'
                    onClose={onClose}
                    finalFocusRef={btnRef}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Menu</DrawerHeader>

                        <DrawerBody>
                            {
                                menuItems.map((link) => (
                                    <NavItem name={link.name} icon={link.icon} link={link.link} onClose={onClose} />
                                ))
                            }
                        </DrawerBody>

                        <DrawerFooter>


                            <Box>
                                <ChakraLink onClick={onClose} to={"/de"} as={ReactRouterLink}>Deutsch</ChakraLink>
                            </Box>
                            <Spacer />
                            {Contacts()}
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Center>
        </Flex>
    );
}
