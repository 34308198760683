import {
    Text,
    Image,
    Flex,
    Spacer,
    List,
    ListItem,
    Highlight,
    Link as ChakraLink,
    SimpleGrid,
} from "@chakra-ui/react"

import {
    MapCard,
    ActivitesCard
} from '../utils/utils'

import { Link as ReactRouterLink } from 'react-router-dom'

import {
    COLOR_Pink,
    COLOR_Link,
} from '../utils/colors'

import {
    PageContent,
    Page,
    AlignedText
} from '../utils/utils'


export const InfosCeremonie = () => {

    const pageContentArr: PageContent[] = [
        {
            title: "Cérémonie",
            body:
                <Text>
                    <List spacing={8}>
                        <ListItem>
                            <AlignedText>
                                <Highlight
                                    query={['le 17 août 2024 à 14h']}
                                    styles={{ px: '2', py: '1', rounded: 'full', bg: COLOR_Pink }}>
                                    La Cérémonie, accompagnée d'une messe, aura lieu le 17 août 2024 à 14h en l'Eglise des Pommerats.
                                </Highlight>
                            </AlignedText>

                            <MapCard
                                name={"Eglise des Pommerats"}
                                image={'https://upload.wikimedia.org/wikipedia/commons/7/7b/2016-Les-Pommerats-Eglise.jpg'}
                                map={'https://maps.app.goo.gl/92im64n1SSU3goRG8'}
                                street={'Haut du Village 41'}
                                city={'2353 Les Pommerats'}
                            />
                        </ListItem>

                        <ListItem>
                            <AlignedText>
                                A l'issu de la Cérémonie, le cortège des voitures rejoindra la Halle du Marché Concours, où un apéritif vous attendra.<><p><br></br></p></>
                            </AlignedText>
                            <AlignedText as='b' color={'#990000'} >
                                &#x2757;&#x2757;&#x2757;À tous les non-jurassien&#x2757;&#x2757;&#x2757;</AlignedText>
                            <AlignedText>
                                Il y aura du <ChakraLink isExternal={true} color={COLOR_Link} textDecoration="underline" href={'https://www.swissmilk.ch/fr/recettes-idees/recettes/LM201303_94/totche/'}>totché</ChakraLink> à l'apéritif!
                                Ce gâteau a l'apparence du gâteau du Vully. Mais ne vous y méprenez pas, il est salé! C'est bien entendu un délice...
                            </AlignedText>
                        </ListItem>
                    </List>
                </Text>
        },
        {
            title: "Arrivée",
            body:
                <List spacing={8}>
                    <ListItem>
                        <AlignedText>
                            Si vous désirez venir en transports en commun, écrivez-nous! Nous organiserons une navette de la gare de Saignelégier jusqu'au village des Pommerats qui partira en fonction de vos différentes heures d'arrivée.
                        </AlignedText>
                    </ListItem>

                    {/*<ListItem>
                        <AlignedText>
                            Il existe différentes places de parking aux alentours de la Collégiale de Saint-Ursanne.
                            Pour rejoindre le cortège plus facilement, il est préférable de parquer votre voiture
                            aux emplacements indiqués sur la carte ci-dessous (c.f., icônes bleues).
                        </AlignedText>
                        <AlignedText>
                            Pour les adeptes des transports en commun, la gare de Saint-Ursanne se trouve également à proximité de la Collégiale (c.f., icône jaune).
                        </AlignedText>
                    </ListItem>

                    <ListItem>
                        <Flex >
                            <Spacer />
                            <Image maxH={'500px'} boxSize='500px' src='img/stursanne.png' alt='situation' width='auto' height='auto' />
                            <Spacer />
                        </Flex>
                    </ListItem>*/}

                </List>
        },
        {

            title: "Départ du cortège",
            body:
                <List spacing={8}>
                    <ListItem>
                        <AlignedText>
                            À la fin de la cérémonie, des décorations pour votre voiture vous seront distribuées. Ne partez pas sans en avoir reçu!
                            Si le chauffeur de tête ne se trompe pas d'itinéraire &#128521;, nous emprunterons de petites routes pittoresques. Laissez-vous donc bercer par les beautés des Franches-Montagnes
                            jusqu'à <ChakraLink to={"/souper"} color={COLOR_Link} as={ReactRouterLink} textDecoration="underline">la Halle du Marché Concours de Saignelégier</ChakraLink>!
                        </AlignedText>
                    </ListItem>

                    {/* <ListItem>
                        <Flex>
                            <Spacer />
                            <Image maxH={'500px'} boxSize='500px' src='img/cortege.gif' alt='cortege' width='auto' height='auto' />
                            <Spacer />
                        </Flex>
                    </ListItem> */}
                </List>
        }
    ];

    return Page(pageContentArr);
}


export const InfosSouper = () => {
    const pageContentArr: PageContent[] = [
        {
            title: "Souper",
            body:
                <List spacing={8}>
                    <ListItem>
                        <AlignedText>
                            Le cortège se dirigera vers la Halle du Marché concours à Saignelégier.
                            Il y a suffisamment de places de parking aux abords de la Halle pour toutes les voitures.
                            Pour les personnes à cheval ou en calèche, des écuries se trouvent à l'arrière du bâtiment &#128014;.

                            Pour les personnes qui ont une réservation d'hôtel, l'apéritif constitue l'occasion idéal pour récupérer la clé de votre chambre et y déposer vos bagages.
                            Vous serez ainsi prêts à sauter directement dans votre lit à la fin de la soirée.
                        </AlignedText>
                    </ListItem>

                    <ListItem>
                        <MapCard
                            name={"Halle du Marché Concours"}
                            image={'https://www.saignelegier.ch/FileDownload/Image/84'}
                            map={'https://maps.app.goo.gl/9tLL9tP8xf4MHet29'}
                            street={'Chemin du Chasseral 1'}
                            city={'2350 Saignelégier'}
                        />
                    </ListItem>
                </List>
        },
        {
            title: "Situation",
            body:
                <List spacing={8}>
                    <ListItem>
                        <AlignedText>
                            La Halle du Marché Concours se trouve sur les hauteurs du chef-lieu des Franches-Montagnes.
                            Les adeptes des transports en commun trouveront un petit train rouge qui les attendra à la gare de Saignelégier à proximité de la Halle.
                            Attention aux <ChakraLink isExternal={true} color={COLOR_Link} textDecoration="underline" href={'https://www.j3l.ch/fr/P33665/a-faire/sport-loisirs/fun-aventure/attaque-de-train'}>cow-boys</ChakraLink> !
                        </AlignedText>
                    </ListItem>

                    <ListItem>
                        <AlignedText>
                            Pour les amateurs de joggings ou de randonnée, de très jolis sentiers se trouvent également aux alentours direct de la halle.
                            Si passer uniquement une journée dans le Jura vous laisse un goût d'inachevé, pourquoi ne pas en profiter pour planifier une activité <ChakraLink to={"/activites"} color={COLOR_Link} as={ReactRouterLink} textDecoration="underline">le dimanche</ChakraLink>?
                        </AlignedText>
                    </ListItem>
                    <ListItem>
                        <Flex >
                            <Spacer />
                            <Image maxH={'500px'} src='img/SaignelegierParkings.png' alt='situation' width='auto' height='auto' />
                            <Spacer />
                        </Flex>
                    </ListItem>
                </List>
        }
    ];
    return Page(pageContentArr);
}

export const InfosActivites = () => {
    const pageContentArr: PageContent[] = [
        {
            title: "Pofiter des Franches-Montagnes",
            body:
                <>
                    <AlignedText>
                        Si l'éventualité d'un doux week-end dans les Franches-Montages résonne dans votre coeur, Saignlégier et ses alentours offre une large gamme de différentes offres hôtelières.
                        N'hésitez pas à nous contacter si vous peinez à trouver la perle rare.
                        Pour les plus assidus d'entre vous, il y a également la possibilité de passer la fin de la nuit dans la Mezzanine de la Halle du Marché Concours.
                        Il s'agit d'un simple plancher en bois, donc pensez peut-être à prendre un matelas de camping et un sac de couchage avec vous!
                    </AlignedText>
                    <AlignedText>
                        Afin d'être d'attaque pour (re)découvrir les franches-montagnes, un brunch réparateur vous sera servi dans la Halle du Marché Concours.
                        Vous serez donc en parfaite forme pour profiter de votre dimanche jurassien. Ci-dessous, vous trouverez quelques recommandations d'activitées toutes testées et validées par les mariés.

                        Voilà, maintenant que tout est dit, il ne reste plus qu'à vous <ChakraLink to={"/registration"} color={COLOR_Link} as={ReactRouterLink} textDecoration="underline">inscrire</ChakraLink>!
                        Nous nous réjouissons de votre présence.
                    </AlignedText>

                    <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>

                        <ActivitesCard
                            name="Bains"
                            picture="https://static.mycity.travel/manage/uploads/8/59/63549/2/hotel-cristal-saignelegier_800.jpg"
                            description="Se relaxer dans les bains de Saignelégier [durée: jusqu'à 3 heures]"
                            link="https://jura-resort.ch/spa-wellness/spa-wellness" />
                        <ActivitesCard
                            name="Randonnée"
                            picture="https://static.mycity.travel/manage/uploads/8/49/214697/1/etang-de-la-gruere_2000.jpg"
                            description="Une randonnée dans une réserve naturelle autour du magnifique étang de la Gruère [durée: 50 minutes, au plat]."
                            link="https://www.loisirs.ch/balades/22214/balade-autour-de-l-etang-de-gruere" />
                        <ActivitesCard
                            name="Trotinettes"
                            picture="https://www.bcj.ch/Htdocs/Images/Pictures/puid_04203c24-f5ab-472d-8117-84b4afd8e23c_4228.jpg"
                            description="Un tour en trotinettes de Saignelégier à Glovelier [durée: 3h]"
                            link="https://www.les-cj.ch/Offres/Loisirs/A-trottinette" />
                        <ActivitesCard
                            name="Calèche"
                            picture="https://static.mycity.travel/manage/uploads/8/59/65967/1/balade-en-char-attele_2000.JPG"
                            description="Une ballade en calèche tirée par des chevaux au départ de Saignelégier [durée: 1h30]."
                            link="https://www.j3l.ch/fr/P34208/a-faire/sport-loisirs/cheval/balade-en-char-attele" />

                    </SimpleGrid>
                </>
        }
    ];
    return Page(pageContentArr);
}


/*export const InfosCeremonie = () => {

    const pageContentArr: PageContent[] = [
        {
            title: "Cérémonie",
            body:
                <Text>
                    <List spacing={8}>
                        <ListItem>
                            <AlignedText>
                                <Highlight
                                    query={['le 17 août 2024 à 14h']}
                                    styles={{ px: '2', py: '1', rounded: 'full', bg: COLOR_Pink }}>
                                    La Cérémonie, accompagnée d'une messe, aura lieu le 17 août 2024 à 14h en la Collégiale de Saint-Ursanne.
                                </Highlight> Libre à vous de venir plus tôt et apprécier les charmes de <ChakraLink isExternal={true} color={COLOR_Link} textDecoration="underline" href={'https://www.j3l.ch/fr/Z10869/lieux/villes/st-ursanne'}>cette vieille ville nichée au bords du Doubs</ChakraLink>.
                            </AlignedText>

                            <MapCard
                                name={"Collégiale de Saint-Ursanne"}
                                image={'https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Saint-Ursanne%2C_la_coll%C3%A9giale-2.jpg/320px-Saint-Ursanne%2C_la_coll%C3%A9giale-2.jpg'} // https://upload.wikimedia.org/wikipedia/commons/3/35/Saint-Ursanne%2C_la_coll%C3%A9giale-2.jpg'}
                                map={'https://maps.app.goo.gl/YiJGUm54zUGy1E6v5'}
                                street={'Rue de la Tour 2'}
                                city={'2882 Saint-Ursanne'}
                            />
                        </ListItem>

                        <ListItem>
                            <AlignedText>
                                A l'issu de la Cérémonie, nous vous invitons à venir partager un apéritif dans le cloître de la Collégiale.<><p><br></br></p></>
                            </AlignedText>
                            <AlignedText as='b' color={'#990000'} >
                                &#x2757;&#x2757;&#x2757;À tous les non-jurassien&#x2757;&#x2757;&#x2757;</AlignedText>
                            <AlignedText>
                                Il y aura du <ChakraLink isExternal={true} color={COLOR_Link} textDecoration="underline" href={'https://www.swissmilk.ch/fr/recettes-idees/recettes/LM201303_94/totche/'}>totché</ChakraLink>!
                                Ce gâteau a l'apparence du gâteau du Vully. Mais ne vous y méprenez pas, il est salé! C'est bien entendu un délice...
                            </AlignedText>
                        </ListItem>
                    </List>
                </Text>
        },
        {
            title: "Arriver et se parquer",
            body:
                <List spacing={8}>
                    <ListItem>
                        <AlignedText>
                            Il existe différentes places de parking aux alentours de la Collégiale de Saint-Ursanne.
                            Pour rejoindre le cortège plus facilement, il est préférable de parquer votre voiture
                            aux emplacements indiqués sur la carte ci-dessous (c.f., icônes bleues).
                        </AlignedText>
                        <AlignedText>
                            Pour les adeptes des transports en commun, la gare de Saint-Ursanne se trouve également à proximité de la Collégiale (c.f., icône jaune).
                        </AlignedText>
                    </ListItem>

                    <ListItem>

                        <Flex >
                            <Spacer />
                            <Image maxH={'500px'} boxSize='500px' src='img/stursanne.png' alt='situation' width='auto' height='auto' />
                            <Spacer />
                        </Flex>
                    </ListItem>

                </List>
        },
        {

            title: "Départ du cortège",
            body:
                <List spacing={8}>
                    <ListItem>
                        <AlignedText>
                            À la fin de l'apéritif, des décorations pour votre voiture vous seront distribuées. Ne partez pas sans en avoir reçu!
                            Vous pouvez les accrocher sur votre voiture et vous mettre sur le bas-côté de la route en attendant
                            que passe la voiture des mariés.
                            Au passage de cette dernière, joignez-vous au cortège.
                            Si le chauffeur de tête ne se trompe pas d'itinéraire &#128521;, nous emprunterons de petites routes pittoresques. Laissez-vous donc bercer par les beautés des Franches-Montagnes
                            jusqu'à <ChakraLink to={"/souper"} color={COLOR_Link} as={ReactRouterLink} textDecoration="underline">la Halle du Marché Concours de Saignelégier</ChakraLink>!
                        </AlignedText>
                    </ListItem>

                    <ListItem>
                        <Flex>
                            <Spacer />
                            <Image maxH={'500px'} boxSize='500px' src='img/cortege.gif' alt='cortege' width='auto' height='auto' />
                            <Spacer />
                        </Flex>
                    </ListItem>
                </List>
        }
    ];

    return Page(pageContentArr);
}*/